import Echo from "laravel-echo";

// 引入script src
function loadScript(src) {
  return new Promise((resolve, reject) => {
    var $script = document.createElement("script");
    $script.src = src;
    $script.onload = resolve;
    $script.onerror = reject;
    document.head.appendChild($script);
  });
}

// 引入socket.js
loadScript(`${process.env.VUE_APP_SOCKET_SRC}socket.io/socket.io.js`)
  .then(() => {
    window.Echo = new Echo({
      broadcaster: "socket.io",
      wsHost: process.env.VUE_APP_SOCKET_SRC,
      wsPath: process.env.VUE_APP_SOCKET_SRC,
      host: process.env.VUE_APP_SOCKET_SRC,
    });
  })
  .catch(() => {
    loadScript(`${process.env.VUE_APP_SOCKET_SRC}socket.io/socket.io.js`)
      .then(() => {
        window.Echo = new Echo({
          broadcaster: "socket.io",
          wsHost: process.env.VUE_APP_SOCKET_SRC,
          wsPath: process.env.VUE_APP_SOCKET_SRC,
          host: process.env.VUE_APP_SOCKET_SRC,
        });
      })
      .catch(() => {
        console.log("連線失敗");
      });
    console.log("連線失敗");
  });
