// 回傳时间
export function returnTime(date: string, full: string, last: boolean) {
  // full = 'all' return 2022-09-23 18:04:00
  // full = 'mon' return 2022-09
  // last = true && full = 'all' 秒數補59 return 2022-09-23 18:04:59
  if (date == "") {
    return date;
  }
  if (isNaN(Date.parse(date))) {
    return date;
  }
  const d = new Date(date),
    year = d.getFullYear();
  let month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    hour = "" + d.getHours(),
    minute = "" + d.getMinutes();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hour.length < 2) hour = "0" + hour;
  if (minute.length < 2) minute = "0" + minute;
  if (full == "mon") {
    return [year, month].join("-");
  }
  const data = [year, month, day].join("-");
  if (full == "all" && last) {
    return data + " " + [hour, minute].join(":") + ":59";
  } else {
    if (full == "all") {
      return data + " " + [hour, minute].join(":") + ":00";
    } else {
      return data;
    }
  }
}
