import cn from "./cn.json";
import en from "./en.json";
import vn from "./vn.json";
import { createI18n } from "vue-i18n";

const messages = {
  cn: {
    ...cn,
  },
  en: {
    ...en,
  },
  vn: {
    ...vn,
  },
};

let i18n = createI18n({
  // 使用 Composition API 模式，则需要将其设置为false
  legacy: false, // 默认是true的
  // 全局注入 $t 函数
  globalInjection: true, // 默认 9.1版本后是true
  locale: "cn",
  messages,
  fallbackLocale: "en", // 没有英文的时候默认中文语言
});

export default i18n;
