import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../src/assets/sass/main.scss";
import NutUI from "@nutui/nutui";
// import { Locale } from "@nutui/nutui";
import "@nutui/nutui/dist/style.css";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import "./utils/socketHandler.js";
import VueClipboard from "vue3-clipboard";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import i18n from "./locales";

// Locale.use("zh-CN");

const app = createApp(App);

library.add(fas, far, fab);
dom.watch();
app.component("font-awesome-icon", FontAwesomeIcon);

app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
});

app.use(i18n);
app.use(store);
app.use(router);
app.use(NutUI);
app.mount("#app");
