import { createStore } from "vuex";
import axios from "../utils/axios";
import { returnTime } from "../utils/utils";
import router from "../router";
export default createStore({
  state: {
    lang: "",
    selfData: {} as Record<any, unknown>,
    pageData: {
      logo: {
        url: "",
      },
      banner: [] as any,
      rate_vnd: {
        rate: 0,
      } as any,
    },
    goods: [] as any,
    isLogin: false,
    isFirst: true,
    orderList: [],
    unreadMsg: 0,
    isLoad: false,
    firstOrder: false,
    notifyMsg: {} as any,
    notifyOrder: {} as any,
    category: {} as any,
    toDaySOrder: 0,
    orderExcept: {} as any,
    toDayWithdraw: 0,
    todayOrder: 0,
  },
  mutations: {
    setLang(state, lang) {
      state.lang = lang;
    },
    loadHandler(state, boolean) {
      state.isLoad = boolean;
    },
    setSelfData(state, selfData) {
      state.selfData = selfData;
    },
    setPageData(state, data) {
      state.pageData = data;
    },
    setGoods(state, data) {
      const good = data;
      good.forEach((e: any) => {
        if (!e.name) {
          e.name = " ";
        }
      });
      state.goods.push(...(good as Array<string | unknown>));
    },
    resetGoods(state) {
      state.goods = [];
    },
    setIsLogin(state, boolean) {
      state.isLogin = boolean;
    },
    setIsFirst(state) {
      state.isFirst = false;
    },
    setExcept(state, except) {
      state.orderExcept = except;
    },
    setExcept2(state, except) {
      const data = state.orderExcept;
      data.status[0] = except.status[0];
      state.orderExcept = data;
    },
    setOrder(state, order) {
      state.orderList = order;
      if (!state.firstOrder) {
        let toDaySOrder = 0;
        order.forEach((e: any) => {
          if (e.completedAt && e.status == 2) {
            const isToday =
              new Date().toDateString() ===
              new Date(e.completedAt).toDateString();
            if (isToday) {
              const num = e.totalPrice - e.totalCost;
              toDaySOrder += num;
            }
          }
        });
        state.toDaySOrder = toDaySOrder;
        state.unreadMsg = state.orderList.reduce((a: any, b: any) => {
          return a + b.unreadCount;
        }, 0);
      }
    },
    setAllUnread(state, num) {
      state.unreadMsg = num;
    },
    setUnread(state, data) {
      state.notifyOrder = data;
      state.notifyMsg = data;
      const currentOrder = JSON.parse(JSON.stringify(state.orderList));
      console.log(currentOrder);
      currentOrder.forEach((e: any) => {
        if (e.tradeId == data.tradeId) {
          e.unreadCount += 1;
        }
      });
      if (router.currentRoute.value.name != "orders") {
        state.unreadMsg += 1;
      }
      state.orderList = currentOrder;
    },
    setNewOrder(state, note: any) {
      const currentOrder = state.orderList as any;
      currentOrder.push(note.order);
      state.unreadMsg += 1;
      state.orderList = currentOrder;
      state.notifyOrder = note;
    },
    cancelOrder(state, note: any) {
      const currentOrder = state.orderList as any;
      currentOrder.push(note.order);
      state.unreadMsg += 1;
      state.orderList = currentOrder;
    },
    setCompleteOrder(state, note: any) {
      const currentOrder = state.orderList as any;
      currentOrder.push(note.order);
      state.unreadMsg += 1;
      state.orderList = currentOrder;
    },
    setBalance(state, note) {
      const self = state.selfData;
      self.balance = note.balance;
      state.selfData = self;
    },
    getCategorys(state) {
      axios.get(`/categorys`).then((res) => {
        if (res.code == 0) {
          const resData: any = res.data as any;
          state.category = resData;
        }
      });
    },
    setWithdraw(state, data) {
      let num = 0;
      num += data.except.amountCompleted;
      num += data.except.amountFailed;
      num += data.except.amountPendding;
      state.toDayWithdraw = num;
    },
    setTodayOrder(state, data: any) {
      if (Array.isArray(data)) {
        state.unreadMsg = data.reduce((a: any, b: any) => {
          return a + b.unreadCount;
        }, 0);
        state.todayOrder = data.length;
      }
      if (data == 1) {
        state.todayOrder += 1;
      }
    },
  },
  actions: {
    getPages(context) {
      axios.get(`/pages`).then((res) => {
        if (res.code == 0) {
          const currentLang =
            localStorage.getItem("lang") == "vn" ? "vi-VN" : ("zh-CN" as any);
          context.commit("getCategorys");
          const resData = res.data as Record<string, unknown>;
          if (resData.favicon) {
            const favicon = document.querySelector('link[rel="icon"]') as any;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (resData.favicon[currentLang]?.url) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              favicon.href = resData.favicon[currentLang]?.url as any;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            document.title = resData.favicon[currentLang]?.pageName;
          }
          if (resData.logo) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            resData.logo = resData.logo[currentLang];
          }
          if (resData.favicon) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            resData.favicon = resData.favicon[currentLang];
          }
          context.commit("setPageData", resData);
        }
      });
    },
    getGoods(context, params) {
      axios.get(`/goodsQuery`, params).then((res) => {
        if (res.code == 0) {
          context.commit("setGoods", res.data);
        }
      });
    },
    async getSelf(context) {
      await axios.get(`/info`).then((res) => {
        context.commit("setIsFirst");
        if (res.code == 0) {
          const resData = res.data as Record<string, unknown>;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (window.Echo) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.Echo.leave(`App.User.${resData.id}`);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.Echo.private(`App.User.${resData.id}`) // 消息名称
              .notification((note: any) => {
                if (note.type == "orderMessage") {
                  context.commit(`setUnread`, note);
                }
                if (note.type == "newOrder") {
                  context.commit(`setNewOrder`, note);
                  this.dispatch("getExcept");
                }
                if (note.type == "cancelOrder") {
                  context.commit(`setNewOrder`, note);
                }
                if (note.type == "completedOrder") {
                  this.dispatch("getExcept");
                  context.commit(`setNewOrder`, note);
                }
                if (note.type == "balanceChanged") {
                  context.commit(`setBalance`, note);
                }
              });
          }
          context.commit("setSelfData", res.data);
          context.commit("setIsLogin", true);
        } else {
          context.commit("setIsLogin", false);
        }
        return true;
      });
    },
    getExcept({ commit }) {
      const params = {
        startTime: returnTime(String(new Date()), "alls", false) + " 00:00:00",
        endTime: returnTime(String(new Date()), "alls", false) + " 23:59:59",
        perPage: 1000,
        page: 1,
      } as any;
      params.timeAt = "completedAt";
      axios.get(`/orders`, params).then((res) => {
        if (res.code == 0) {
          commit(`setExcept`, res.except);
        }
      });
      delete params.timeAt;
      axios.get(`/orders`, params).then((res) => {
        if (res.code == 0) {
          commit(`setTodayOrder`, res.data);
          setTimeout(() => {
            commit(`setExcept2`, res.except);
          }, 2000);
        }
      });
      axios
        .get(`/withdraw`, {
          start: params.startTime,
          end: params.endTime,
          page: 1,
          perPage: 1000,
        })
        .then((res) => {
          if (res.code == 0) {
            commit(`setWithdraw`, res);
          }
        });
      // axios.get(`/orders`, params).then((res) => {
      //   if (res.code == 0) {
      //     commit(`setExcept`, res.except);
      //   }
      // });
    },
    getOrders({ commit }, params) {
      // if (!state.firstOrder) {
      //   params.timeAt = "completedAt";
      // }
      // axios.get(`/orders`, params).then((res) => {
      //   if (res.code == 0) {
      //     commit(`setOrder`, res.data);
      //     // commit(`setExcept`, res.except);
      //   }
      // });
      axios
        .get(`/withdraw`, {
          start: params.startTime,
          end: params.endTime,
          page: params.page,
          perPage: 15,
        })
        .then((res) => {
          if (res.code == 0) {
            commit(`setWithdraw`, res);
          }
        });
    },
  },
  modules: {},
});
