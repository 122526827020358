// eslint-disable-next-line
// @ts-nocheck
import axios, {
  AxiosInstance,
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

import { showNotify } from "@nutui/nutui";
import store from "../store/index";
import axiosConfig from "@/config";
import router from "../router/index";
// import { showToast } from "@nutui/nutui";

const {
  api: { devApiBaseUrl, proApiBaseUrl },
} = axiosConfig;

let token = localStorage.getItem("token") || "";

const urlParams = new URLSearchParams(window.location.search);

if (urlParams.get("token") && urlParams.get("id")) {
  // localStorage.setItem("token", urlParams.get("token") as string);
  token = urlParams.get("token");
}

const headers = {
  Authorization: "Bearer " + token,
  // 分身登入
  id: urlParams.get("id") ? urlParams.get("id") : "",
};
// 數據返回的接口
// 定義请求響應參數，不含data
interface Result {
  code: number;
  msg: string;
}
// 请求響應參數，包含data
interface ResultData<T = string | Record<string, unknown>> extends Result {
  message?: T;
  paginate?: T;
  data?: T;
  except?: T;
}

// 返回 code
enum RequestEnums {
  TIMEOUT = 20000,
  OVERDUE = 10001, // 登入令牌失效
  SUCCESS = 0, // 请求成功
}

const config = {
  // 默認地址
  baseURL: process.env.APP_ENV === "production" ? devApiBaseUrl : proApiBaseUrl,
  timeout: RequestEnums.TIMEOUT as number,
  withCredentials: true,
};

const request = [];
class RequestHttp {
  service: AxiosInstance;
  public constructor(config: AxiosRequestConfig) {
    this.service = axios.create(config);
    this.service.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        if (
          !(
            config.url?.indexOf("message") != -1 &&
            config.url?.indexOf("orders") != -1
          )
        ) {
          request.push(config.url.split("?")[0]);
          store.commit("loadHandler", true);
        }
        return {
          ...config,
          headers,
        };
      },
      (error: AxiosError) => {
        Promise.reject(error);
      }
    );

    this.service.interceptors.response.use(
      (response: AxiosResponse) => {
        request.splice(request.indexOf(response.config.url.split("?")[0]), 1);
        setTimeout(() => {
          if (request.length == 0) {
            store.commit("loadHandler", false);
          }
        }, 500);
        const { data } = response;
        // 登入令牌失效 > 改為未登入狀態 & 跳轉 & 清空令牌
        if (data.code === RequestEnums.OVERDUE) {
          router.push("/login");
          store.commit("setIsLogin", false);
          localStorage.removeItem("token");
          // ElMessage.error("请重新登入");
          return data;
        } // 全局錯誤信息攔截
        if (data.code && data.code !== RequestEnums.SUCCESS) {
          showNotify.text(data.message, {
            onClose: () => {
              console.log("close");
            },
            onClick: () => {
              console.log("click");
            },
          });
          return data;
        }
        return data;
      },
      (error: AxiosError) => {
        request.splice(request.indexOf(error.config.url.split("?")[0]), 1);
        setTimeout(() => {
          if (request.length == 0) {
            store.commit("loadHandler", false);
          }
        }, 1500);
        const { response } = error;
        if (response) {
          this.handleCode(response.status);
        }
        return {};
      }
    );
  }
  handleCode(code: number): void {
    switch (code) {
      case 401:
        // ElMessage.error("登入失效，请重新登入");
        break;
      default:
        // ElMessage.error("请求失敗");
        break;
    }
  }

  // 设置额外headers
  setHeader(key: string, content: string) {
    headers[key] = content;
  }

  // 请求封裝
  get<T>(
    url: string,
    params?: Record<string, unknown>
  ): Promise<ResultData<T>> {
    return this.service.get(url, { params });
  }
  post<T>(
    url: string,
    params?: Record<string, unknown> | FormData | undefined
  ): Promise<ResultData<T>> {
    return this.service.post(url, params);
  }
  put<T>(
    url: string,
    params?: Record<string, unknown>
  ): Promise<ResultData<T>> {
    return this.service.put(url, params);
  }
  delete<T>(
    url: string,
    params?: Record<string, unknown>
  ): Promise<ResultData<T>> {
    return this.service.delete(url, { params });
  }
}

export default new RequestHttp(config);
