import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "../store/index";
import { returnTime } from "../utils/utils";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "wrap",
    component: () => import("../components/homeWrap.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../views/HomeView.vue"),
      },
    ],
  },
  {
    path: "/selfData",
    name: "selfData",
    component: () => import("../views/selfData.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/",
    name: "contentWrap",
    component: () => import("../components/contentWrap.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("../views/login.vue"),
      },
      {
        path: "/report",
        name: "report",
        component: () => import("../views/report.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/selInfo",
        name: "selInfo",
        component: () => import("../views/selInfo.vue"),
      },
      {
        path: "/shopSearch",
        name: "shopSearch",
        component: () => import("../views/shopSearch.vue"),
      },
    ],
  },
  {
    path: "/star",
    name: "star",
    component: () => import("../views/star.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/good/:name/:addAt",
    name: "good",
    component: () => import("../views/GoodView.vue"),
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("../views/orders.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import("../views/cart.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/category",
    name: "category",
    component: () => import("../views/category.vue"),
  },
  {
    path: "/product",
    name: "product",
    component: () => import("../views/product.vue"),
  },
  {
    path: "/favorite",
    name: "favorite",
    component: () => import("../views/favorite.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/walletLogs",
    name: "walletLogs",
    component: () => import("../views/walletLogs.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/wallet",
    name: "wallet",
    component: () => import("../views/wallet.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: () => import("../views/withdraw.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/deposit",
    name: "deposit",
    component: () => import("../views/deposit.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/selInfo",
    name: "selInfo",
    component: () => import("../views/selInfo.vue"),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
const urlParams = new URLSearchParams(window.location.search);
router.beforeEach(async (to, from) => {
  if (store.state.isFirst) {
    if (localStorage.getItem("token") || urlParams.get("token")) {
      if (from.name != "login") {
        await store.dispatch("getSelf");
      }
      if (to.name != "orders") {
        const day1 = new Date();
        day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000);
        const s1 =
          day1.getFullYear() +
          "-" +
          (String(day1.getMonth() + 1).length == 1
            ? "0" + (day1.getMonth() + 1)
            : day1.getMonth() + 1) +
          "-" +
          (String(day1.getDate()).length == 1
            ? "0" + day1.getDate()
            : day1.getDate());
        store.dispatch("getExcept");
        // store.dispatch("getOrders", {
        //   startTime:
        //     returnTime(String(new Date()), "alls", false) + " 00:00:00",
        //   endTime: returnTime(String(new Date()), "alls", false) + " 23:59:59",
        //   perPage: 1000,
        //   page: 1,
        // });
      }
    }
    // await store.dispatch("getSelf");
  }
  if (to.meta?.requiresAuth && !store.state.isLogin) {
    return { name: "login" };
  }

  window.scrollTo(0, 0);
});
export default router;
